<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-loading="isLoading" v-if="formAction === 'select'">
      <div class="block">
        <img
          :src="
            contentFormInfo.logo_url ||
              contentFormInfo.service_provider.data.logo_url ||
              '/Five9Logo.jpg'
          "
          @error="handleAltImg"
          style="width: 125px; height: 125px; text-align: left; object-fit: contain "
        />
      </div>
      <div class="titles">{{ __("Account Name") }}</div>
      <div class="details">{{ accountNameLabel }}</div>
      <div class="titles">{{ __("Account ID") }}</div>
      <div class="details">{{ contentFormInfo.ac_id }}</div>

      <div class="titles">{{ __("Linked Domain") }}</div>
      <div class="details">{{ domainLabel || "-" }}</div>

      <div class="titles">{{ __("Account Note") }}</div>
      <div class="details">{{ contentFormInfo.note }}</div>
      <div class="titles">{{ __("Account Status") }}</div>
      <div class="details">
        {{ accountStatusDetail }}
      </div>
      <div class="titles">{{ __("Account Display Date Format") }}</div>
      <div class="details">
        {{
          contentFormInfo.display_date_format === ""
            ? "yyyy/MM/dd"
            : contentFormInfo.display_date_format
        }}
      </div>
      <div class="titles">{{ __("Account TimeZone") }}</div>
      <div class="details">
        {{
          contentFormInfo.default_timezone === ""
            ? "UTC"
            : contentFormInfo.default_timezone
        }}
      </div>
      <div class="titles">{{ __("Account Outbound Threads") }}</div>
      <div class="details">{{ contentFormInfo.total_outbound_threads }}</div>
      <div style="margin-bottom: 10px;" v-show="contentFormInfo.is_enabled">
        <div class="titles">{{ __("Use File Service") }}</div>
        <div class="details">
          <el-tooltip
            :content="useFileSvcFieldLabel()"
            :open-delay="300"
            placement="bottom"
            :disabled="!disableFileSvcToggle"
          >
            <el-switch
              :value="contentFormInfo.use_file_svc"
              :disabled="disableFileSvcToggle"
              @change="toggleFileSvc"
            ></el-switch>
          </el-tooltip>
        </div>
      </div>

      <div class="titles">{{ __("Account Migration State") }}</div>
      <div class="details">
        {{ migrationStates[contentFormInfo.migration_state] }}
      </div>
      <div style="display: flex; padding-top: 20px">
        <el-dropdown @command="handleAction" class="allAcActionDropDown">
          <el-button type="primary">
            {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="!can('manage.accounts.write')"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="account-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Account')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            id="contentForm"
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Name')" prop="ac_name">
                  <el-input
                    v-model="contentForm.ac_name"
                    v-loading="isCheckingName"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Note')" prop="note">
                  <el-input v-model="contentForm.note"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Logo')" prop="logopath">
                  <logo-uploader
                    :folder="logoFolder"
                    :file-url="contentForm.logo_url"
                    :fit="logoFit"
                    @on-success="handleSuccess"
                    @on-error="handleError"
                  >
                    <div slot="tip" class="el-upload__tip">jpg/jpeg files</div>
                  </logo-uploader>
                </el-form-item></el-col
              >
            </el-row>
            <div v-if="formAction === 'edit'">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item label="Outbound Threads" prop="ob_thread">
                    <el-slider
                      style="margin-left: 5px"
                      v-model="contentForm.total_outbound_threads"
                      show-input
                      :max="ob_max"
                      :show-tooltip="false"
                      :disabled="
                        formAction === 'add' || contentForm.ob_disabled
                      "
                    ></el-slider>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="" prop="display_date_format">
                  <display-date-format-dropdown
                    v-model="contentForm.display_date_format"
                    @display-date-format-changed="displayDateFormatChanged"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Timezone')"
                  prop="default_timezone"
                  required
                >
                  <timezone-dropdown
                    :default_timezone="contentForm.default_timezone"
                    @timezone-changed="handleTimezoneChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('QforMe Trunk')" prop="trunk_id">
                  <el-select
                    filterable
                    v-model="contentForm.trunk_id"
                    default-first-option
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in trunks(selectedServiceProviderId)"
                      :key="index"
                      :label="item.label"
                      :value="item.value.trunk_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Password Policy')"
                  prop="password_policy_id"
                >
                  <el-select
                    filterable
                    v-model="contentForm.password_policy_id"
                    default-first-option
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in policies"
                      :key="index"
                      :label="item.policy_name"
                      :value="item.password_policy_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Link Domain')"
                  prop="domain_id"
                  id="domain_id"
                >
                  <el-select
                    style="width: 100%"
                    v-model="domainLabel"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="__('Please start typing domain id')"
                    default-first-option
                    :remote-method="fetchDomainInfo"
                    :loading="domainLoading"
                    @change="handleDomainSelect"
                    v-loading="isCheckingDomain"
                    :disabled="
                      !userRoleHasSystemRoleOrScope() &&
                        !can('manage.accounts.link-domain')
                    "
                  >
                    <el-option
                      v-for="domain in domains"
                      :key="domain.domain_id"
                      :label="domain.domain_label"
                      :value="domain.domain_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Use File Service')"
                  prop="use_file_svc"
                >
                  <div slot="label" class="form-label">
                    <div>{{ __("Use File Service") }}</div>
                    <el-popover
                      placement="right"
                      width="300"
                      trigger="hover"
                      :content="useFileSvcFieldLabel()"
                    >
                      <i slot="reference" class="el-icon-info icon"></i>
                    </el-popover>
                  </div>
                  <el-switch
                    :disabled="
                      disableFileSvcToggle || !userRoleHasSystemRoleOrScope()
                    "
                    v-model="contentForm.use_file_svc"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Migration State')"
                  prop="migration_state"
                >
                  <el-select
                    v-model="contentForm.migration_state"
                    style="width: 100%"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="(item, key) in migrationStates"
                      :key="key"
                      :label="migrationStates[key]"
                      :value="key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    :loading="isLoading"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!this.canAddEditOrDelete()"
                    :primaryKey="id"
                  />
                  <el-button class="cancelBtn" @click="cancelForm"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import LogoUploader from "@/components/uploaders/types/AvatarUploader";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import TimezoneDropdown from "../../../../components/TimezoneDropdown";
import _ from "lodash";
import { checkUniqueName, checkUniqueDomain } from "@/api/accounts";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";
import * as AccountsUtil from "@/utils/accounts";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    LogoUploader,
    TimezoneDropdown,
    DisplayDateFormatDropdown,
    SaveButton
  },
  props: {
    account: {
      type: Object,
      required: false,
      default: () => ({
        ac_name: "",
        note: "",
        ac_version: "",
        logopath: "",
        default_timezone: "",
        migration_state: ""
      })
    },

    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    const validateAccountName = async (rule, value, callback) => {
      try {
        if (value !== this.contentFormInfo.ac_name || this.id === -1) {
          if (value.length > 100) {
            callback(
              new Error(__("Account name cannot exceed 100 characters"))
            );
          }
          this.isCheckingName = true;
          const res = await checkUniqueName(value);
          this.isCheckingName = false;
          if (res.data.found) {
            callback(new Error(__("Account name already exists")));
          } else {
            callback();
          }
        } else {
          callback();
        }
      } catch (e) {
        this.isCheckingName = false;
        callback();
      }
    };

    const validateNote = async (rule, value, callback) => {
      try {
        if ((value && value !== this.contentFormInfo.value) || this.id === -1) {
          if (value.length > 100) {
            callback(new Error(__("Note cannot exceed 100 characters")));
          }
        } else {
          callback();
        }
      } catch (e) {
        this.isCheckingName = false;
        callback();
      }
    };

    const validateAccountDomain = async (rule, value, callback) => {
      try {
        this.isCheckingDomain = true;

        value = this.contentForm.ac_domain_map.data.domain_id;
        if (
          value &&
          (value !== this.contentFormInfo.ac_domain_map.data.domain_id ||
            this.id === -1)
        ) {
          const res = await checkUniqueDomain(value);

          this.isCheckingDomain = false;
          if (res.data.found) {
            callback(new Error(__("Account domain already in use")));
          } else {
            callback();
          }
        } else {
          this.isCheckingDomain = false;
          callback();
        }
      } catch (e) {
        this.isCheckingDomain = false;
        callback();
      }
    };

    return {
      rules: {
        ac_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Name is required")
          },
          {
            validator: validateAccountName,
            trigger: "blur"
          }
        ],
        note: [
          {
            validator: validateNote,
            trigger: "blur"
          }
        ],
        default_timezone: [
          {
            required: true,
            trigger: "blur",
            message: __("Timezone is required")
          }
        ],
        domain_id: [
          {
            validator: validateAccountDomain,
            trigger: "change"
          }
        ]
      },
      //accountForm: { ...this.account },
      accountFormKey: 0,
      isCheckingName: false,
      isCheckingDomain: false,
      emailReceiverList:
        this.account["email_receiver_list"] == null ||
        this.account["email_receiver_list"] === ""
          ? []
          : this.account["email_receiver_list"].split(","),
      isSubmitting: false,
      logoFolder: "logo",
      logoFileUrl: "",
      logoFit: "contain",
      uploadedFilePath: "",
      ob_max: 1,
      commands: [
        {
          command: "Switch to account",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_reset.svg",
          selectAction: false,
          label: __("Switch to this account"),
          displayActive: true
        },
        {
          command: "createOrEdit",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          selectAction: false
        },
        {
          command: "delete",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Archive"),
          selectAction: false
        },
        {
          command: "active",
          rules: {
            is_enabled: 0
          },
          icon: "accounts/icon_check_complete.svg",
          label: __("Activate"),
          selectAction: false
        },
        {
          command: "force delete",
          rules: {
            is_enabled: 0
          },
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Force Delete"),
          selectAction: false
        }
      ],
      domains: [],
      domainLoading: false
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("accounts", {
      timezones: state => state.timezones,
      isLoading: state => state.isLoading,
      migrationStates: state => state.accountMigrationStates
    }),
    ...mapState("passwordpolicies", {
      policies: state => state.policies
    }),

    ...mapGetters("serviceproviders", {
      trunks: "trunks"
    }),
    commandsToShow() {
      const userRole = this.$store.getters.userRole;
      return _.filter(this.commands, command => {
        if (command.command === "force delete") {
          return (
            userRole === "system" && this.forceDeleteRulesMatch(command.rules)
          );
        }
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return this.contentForm[key] === rule;
        });
      });
    },
    domainLabel: {
      get() {
        let domainMapData = this.contentForm.ac_domain_map.data;
        if (!domainMapData.domain_id) {
          return "";
        }
        return `${domainMapData.domain_name} (${domainMapData.domain_id})`;
      },
      set(val) {
        this.contentForm.ac_domain_map.data.domain_label = val;
        if (_.isEmpty(val)) {
          this.contentForm.use_file_svc = false;
        }
      }
    },

    /**
     * Get the account name label for the currently selected account
     */
    accountNameLabel: function() {
      return AccountsUtil.accountNameLabel(this.contentForm);
    },

    /**
     * Computed property for the account status detail
     */
    accountStatusDetail: function() {
      return this.contentFormInfo.is_enabled ? __("active") : __("inactive");
    },

    /**
     * Determines whether to disable the switcher for Use File Service field
     */
    disableFileSvcToggle() {
      return _.isEmpty(this.domainLabel);
    }
  },

  watch: {
    emailReceiverListString() {
      return this.emailReceiverList.join(",");
    },
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        // console.log(this.contentForm.trunk_id);
        if (val === "edit") {
          if (this.contentForm.ob_max > 0) {
            this.ob_max = this.contentForm.ob_max;
          } else {
            this.ob_max = 1;
            this.contentForm.ob_disabled = true;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("accounts", {
      createAccount: "createAccount",
      updateAccount: "updateAccount",
      deleteContentMethod: "deleteAccount",
      undoDeleteContent: "undoDeleteAccount",
      // addNewApiKeyToAccount: "addNewApiKeyToAccount",
      getDomainInfo: "getDomainInfo",
      forceDeleteContentMethod: "forceDeleteAccount"
    }),

    ...mapActions("upload", {
      deleteLogo: "deleteLogo"
    }),

    ...mapActions("app", {
      changeUserSelectedAccountId: "changeUserSelectedAccountId",
      generateUserAccountSwitcherOptions: "generateUserAccountSwitcherOptions"
    }),

    ...mapGetters("app", {
      userRoleHasSystemRoleOrScope: "userRoleHasSystemRoleOrScope"
    }),

    async fetchDomainInfo(query) {
      if (query !== "") {
        this.domainLoading = true;
        await this.getDomainInfo(query)
          .then(data => {
            this.domains = [];
            _.map(data.data, domain => {
              let domainInfo = {
                domain_id: domain.domain_id,
                domain_name: domain.domain_name,
                domain_label: `${domain.domain_name} (${domain.domain_id})`
              };
              this.domains.push(domainInfo);
            });
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err.message
            });
          });
        this.domainLoading = false;
      } else {
        this.domains = [];
      }
    },

    handleDomainSelect(domain_id) {
      let changedName = "";
      let changedId = "";
      if (domain_id) {
        let domain = _.find(this.domains, { domain_id });
        changedId = domain_id;
        changedName = domain.domain_name;
      }
      this.contentForm.ac_domain_map.data.domain_id = changedId;
      this.contentForm.ac_domain_map.data.domain_name = changedName;
    },
    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAltImg(event) {
      event.target.src =
        this.contentFormInfo.service_provider.data.logo_url || "/Five9Logo.jpg";
    },

    handleSuccess(res) {
      console.log(res.url);
      this.contentForm.logopath = res.path;
      this.contentForm.logo_url = res.url;
      this.uploadedFilePath = res.path;
    },

    async handleTimezoneChange(timezone) {
      this.contentForm.default_timezone = timezone;
    },

    handleError(err) {
      console.log(err);
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createAccount : this.updateAccount;
          this.contentForm.sp_id = this.selectedServiceProviderId;
          this.contentForm.email_receiver_list = this.emailReceiverListString;
          process(this.contentForm)
            .then(data => {
              this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
              // hack to refresh the user form to persist recent modifications
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Account added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Account updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    cancelForm() {
      if (this.id === -1) {
        this.deleteLogo({ path: this.uploadedFilePath })
          .then(() => {
            this.handleCancel();
          })
          .catch(err => {
            console.log(err);
            this.handleCancel();
          });
      }
      this.ob_max = 1;
      this.contentForm.ob_disabled = true;
      this.handleCancel();
    },

    handleSwitch() {
      this.changeUserSelectedAccountId(this.contentForm.ac_id);
      this.generateUserAccountSwitcherOptions();
    },
    handleAction(action) {
      if (action === "delete") {
        this.$confirm(
          // eslint-disable-next-line
          '<strong>' + __("Are you sure you want to archive this account?") +
            "</strong><br/>" +
            // eslint-disable-next-line
          __("Archiving this account will disable any associated Task and Content items."),
          __("Archive Account"),
          {
            confirmButtonText: __("Archive"),
            confirmButtonClass: "el-button--danger",
            cancelButtonText: __("Cancel"),
            dangerouslyUseHTMLString: true,
            type: "warning"
          }
        )
          .then(() => {
            sessionStorage.setItem("actionType", "archive");
            this.handleDelete(this.contentForm);
          })
          .catch(() => {
            // Do nothing
          });
      } else if (action === "createOrEdit") {
        this.handleEdit();
      } else if (action === "active") {
        this.handleActive(this.contentForm);
      } else if (action === "force delete") {
        this.$confirm(
          // eslint-disable-next-line
          '<strong>' + __("Are you sure you want to delete this account?") +
            "</strong><br/>" +
            // eslint-disable-next-line
          + __("Deleting this account will also delete any associated Task and Content items."),
          __("Delete Account"),
          {
            confirmButtonText: __("Delete"),
            confirmButtonClass: "el-button--danger",
            cancelButtonText: __("Cancel"),
            dangerouslyUseHTMLString: true,
            type: "warning"
          }
        )
          .then(() => {
            this.forceDeleteContentMethod(this.contentForm);
          })
          .catch(() => {
            // Do nothing
          });
      } else {
        this.handleSwitch();
      }
    },
    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.contentForm.display_date_format = displayDateFormat;
    },

    /**
     * Show info for Use File service label
     */
    useFileSvcFieldLabel() {
      return __("Link a domain to use the file service");
    },

    /**
     * Toggle Use File service flag for an account using update account api
     *
     */
    toggleFileSvc() {
      this.contentFormInfo.use_file_svc = !this.contentFormInfo.use_file_svc;
      this.updateAccount(this.contentFormInfo)
        .then(data => {
          this.$message({
            type: "success",
            message: __("Account updated successfully")
          });
          EventBus.$emit("list-changed", data.data);
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },
    handleActive(contentForm) {
      this.undoDeleteContent(contentForm).then(() => {
        EventBus.$emit("list-changed");
      });
    },
    forceDeleteRulesMatch(rules) {
      return _.every(rules, (rule, key) => {
        return this.contentForm[key] === rule;
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .account-form-dialog {
  max-height: 90vh !important;
}

.api-key-manage-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  .pl-10 {
    padding-left: 10px;
  }
}

.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}

.allAcActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
